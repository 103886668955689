import {Button} from 'components/Button';
import {Modal} from 'components/Modal';
import {useState} from 'react';

import styles from 'css_modules/Modal.module.css';

export const InfoModal = ({close, errors}) => {
    let dict = {}
    dict["client_company"]="Klientská společnost"
    dict["docType"]="Typ dokladu"
    dict["document_type"]="Typ dokladu"
    dict["document_identifier"]="Popis dokladu"
    dict["number"]="Číslo dokladu"
    dict["supplier_title"]="Název partnera"
    dict["supplier_identification_number"]="IČ partnera"
    dict["taxableSupplyDate"]="Datum zdanitelného plnění"
    dict["dueDate"]="Datum splatnosti"
    dict["accountingDate"]="Datum účetního případu"
    dict["taxDeductionDate"]="Datum odečtu"
    dict["variable_symbol"]="Variabilní symbol"
    dict["supplier_account_number"]="Číslo účtu / IBAN"
    dict["supplier_bank_code"]="Kód banky / SWIFT"
    dict["paymentDate"]="Datum úhrady"
    dict["externalIdValue"]="Číslo v účetním systému"
    dict["num_series"]="Číselná řada"
    dict["unknown_rate"]="Daňová sazba"
    dict["date"]="Datum"
    dict["taxable_supply_date"]="Datum zdanitelného plnění"
    dict["supplier_sk_ic_dph"]="SK IČ DPH"
    dict["rounding"]="Zaokrouhlení"
    
    
    const [errs] = useState([]);
    
    for (let e in errors.info){
        if (!JSON.stringify(errs).includes(e)){
            errs.push([e, errors.info[e],"info."+e])
        }
        if (!dict[""+e]){
            dict[""+e] = ""+e;
        }
        
    }
    
    return (
        <div className={styles.ontop}>
            <Modal closeOnClickOutside close={close} name='Dokument obsahuje chyby'>
                <div className={styles.body}>
                        <table className={styles.infotablebody}>
                            <tbody >
                            <tr className={styles.infohead}>
                                <td>
                                    Pole
                                </td>
                                <td>
                                    Chyba
                                </td>
                            </tr>
                            {errs.map(e => 
                                <tr key={e[0]}>
                                    <td>
                                    {dict[""+e[0]] }
                                    </td>
                                    <td>
                                        {String(e[1]).includes("must be defined")? "Musí být zadáno": e[1]}
                                    </td>
                                </tr>
                            )
                            }
                            </tbody>
                        </table>
                </div>
                <footer className={styles.buttonsingle}>
                    <Button onClick={close}>Zavřít</Button>
                </footer>
            </Modal>
        </div>
    )
};