import React, {useState} from 'react';
import {useFetch} from 'helpers/hooks';
import {useFormikContext} from 'formik';
import {Button} from 'components/Button';
import {Modal} from 'components/Modal';
import styles from 'css_modules/ForwardTreePickerModal.module.css';
import {Select} from 'components/Select';
import {useLocation} from 'react-router-dom'

export const ForwardTreePickerModal = ({close, body,user, clicked, clickedDirectForward, forward,miscellaneousDocType,directForceForward,docsInfos}) => {
    const location = useLocation();
    const selectedManager = useFormikContext();
    const assignable_users = useFetch('/get_assignable_users/?documents='+[...body])
    let toggle_direct_forward_to_accountant =  String(user["toggle_direct_forward_to_accountant"])==="true" ? true:false

    let [selectedManagerId_1, setSelectedManagerId_1] = useState (-1)
    let [selectedManagerId_2, setSelectedManagerId_2] = useState (-2)
    let [selectedManagerId_3, setSelectedManagerId_3] = useState (-3)
    let [selectedManagerId_4, setSelectedManagerId_4] = useState (-4)
    let [selectedManagerId_5, setSelectedManagerId_5] = useState (-5)

    
    let [bottomMessage,setBottomMessage] = useState("  ")
   
    let management_users = []
    let maintenance_users = []
    let management_users_list = []
    let maintenance_users_list = []
    let docsToApproval_noTree = []
    let docsToApproval_withTree = []
    let docsWithoutApproval = []

    if (assignable_users.data){ 
        management_users = assignable_users.data["assignable_users"]["management_users"]
        maintenance_users = assignable_users.data["assignable_users"]["maintenance_users"]

        docsToApproval_noTree = assignable_users.data["documents_with_approval_required"]["documents_without_approval_tree"]
        docsToApproval_withTree = assignable_users.data["documents_with_approval_required"]["documents_with_approval_tree"]
        docsWithoutApproval = assignable_users.data["documents_without_approval_required"]
    }
    let infos_docsToApproval_noTree = []
    let infos_docsToApproval_withTree = []
    let infos_docsWithoutApproval = []
    body.forEach( (id,i)=> {
        
        if (docsInfos.length>0){
            let tit = ""
            if (docsInfos[i]["number"]!==""){
                tit +=docsInfos[i]["number"]+", "
            }
            if (docsInfos[i]["supplier_title"]!==""){
                tit +=docsInfos[i]["supplier_title"]
            }
            if (tit===""){
                tit = id
            }
            
            docsToApproval_noTree.forEach(d => {
 
                if (d.includes(id)){
                    infos_docsToApproval_noTree.push(tit)
                }
            })
          
            docsToApproval_withTree.forEach(d => {
                if (d["document"].includes(id)){
                    tit += d
                    if (String(d["next_management_level"])!=="null"){
                        tit += ' → '+ d["next_user_name"] +' (Úr. '+d["next_management_level"]+ ')'
                    }
                    else {
                        tit += ' → '+ d["next_user_name"] +' (účetní)'
                    }
                   
                    infos_docsToApproval_withTree.push(tit)
                   
                }
            })

            docsWithoutApproval.forEach(d => {
                if (d.includes(id)){
                    infos_docsWithoutApproval.push(tit)
                }
            })
          
        }
        else{
            docsToApproval_noTree.forEach(d => {
                if (d.includes(id)){
                    infos_docsToApproval_noTree.push(d)
                }
            })
            
            docsToApproval_withTree.forEach(d => {
                if (d["document"].includes(id)){
                    infos_docsToApproval_withTree.push(d["document"])
                }
            })

            docsWithoutApproval.forEach(d => {
                if (d.includes(id)){
                    infos_docsWithoutApproval.push(d)
                }
            })
        }
        

        }
    )
    
    
    let maxlevel = 0
    let minlevel = 100
    if (management_users.length>0){
        let levels = []
        
        for (let i = 0; i<management_users.length; i++){
            let l = management_users[i]["management_level"]
            if (!levels.includes(l)){
                levels.push(parseInt(l) )
                if (l>maxlevel){
                    maxlevel = l
                }
                if (l<minlevel){
                    minlevel = l
                }
            }
        }
        
        levels = levels.sort()

        
        levels.forEach(l=>{
                management_users_list.push({
                    id : (-l*10),
                    title : "———————— Úroveň " +l +" ———————— ",
                    name : "► Kdokoliv",
                    level : l,
                    off: "true"
                })
                management_users_list.push({
                    id : (-l),
                    title : "► Kdokoliv",
                    name : "► Kdokoliv",
                    level : l
                })
            
                for (let i = 0; i<management_users.length; i++){
                    let u = management_users[i]
                    let ml = u["management_level"]
                    if (ml===l){
                        management_users_list.push({
                            //id : parseInt(u["management_user_id"]),
                            id : parseInt(u["user_id"]) + 1000*parseInt(u["management_level"]),
                            title : '● '+u["first_name"]+' '+u["last_name"],
                            name : ''+u["first_name"]+' '+u["last_name"],
                            level : parseInt(u["management_level"]),
                            user_id : parseInt(u["user_id"]) 
                        })
                    }
                }
                management_users_list.push({
                    id : (-l*100),
                    title : "× Přeskočit",
                    name : "× Přeskočit",
                    level : l
                })
            }
        )
    }
    
    if (maintenance_users.length>0){
        for (let i = 0; i<maintenance_users.length; i++){
            let u = maintenance_users[i]
            maintenance_users_list.push({
                    //id : parseInt(u["maintenance_user_id"]),
                    id : parseInt(u["user_id"]),
                    title :'○ '+ u["first_name"]+' '+u["last_name"],
                    name : '○ '+u["first_name"]+' '+u["last_name"]
            })
        }

        if (maintenance_users_list.length>0){
            maintenance_users_list.unshift({
                id : -100,
                title : "► Kdokoliv",
                name : "► Kdokoliv",
            })
        }
       
    }

    let management_users_list_1 = []
    management_users_list.forEach(u => {if (u["level"] === 1){management_users_list_1.push(u)} })
    let management_users_list_2 = []
    management_users_list.forEach(u => {if (u["level"] === 2){management_users_list_2.push(u)} })
    let management_users_list_3 = []
    management_users_list.forEach(u => {if (u["level"] === 3){management_users_list_3.push(u)} })
    let management_users_list_4 = []
    management_users_list.forEach(u => {if (u["level"] === 4){management_users_list_4.push(u)} })
    let management_users_list_5 = []
    management_users_list.forEach(u => {if (u["level"] === 5){management_users_list_5.push(u)} })


    const getSelectedManagerUsers = () => {
        let selectedUsers = []
        let id = undefined
        let level = undefined
        let u = undefined
        let myname = undefined
        let user_id = undefined
        if (management_users_list_1.length>0){
            console.log("searching for lvl1 ",selectedManagerId_1)
            if (parseInt(selectedManagerId_1)===-1){
                selectedUsers.push( {
                    "management_user_id" : -1,
                    "level" : 1 ,
                    "name" : "Kdokoliv"
                 }  ) 
            }
            else if (parseInt(selectedManagerId_1) === -100){
                selectedUsers.push( {
                    "management_user_id" : -100,
                    "level" : 1 ,
                    "name" : "Přeskočit"
                 }  ) 
            }
            else{
                for (let i = 0; i<management_users_list_1.length; i++){
                    u = management_users_list_1[i]
                    id = u["id"]
                    user_id = u["user_id"]
                    level = u["level"]
                    myname = 'Úroveň '+level +': '+u["name"]
                    if (parseInt(id) === parseInt(selectedManagerId_1) ){
                        console.log("found",u)
                        selectedUsers.push( {"management_user_id" : user_id , "level" : level, 
                        "name" : myname}  ) 
                    }
                }
            }
        }

        if (management_users_list_2.length>0){
            console.log("searching for lvl2 ",selectedManagerId_2)
            if (parseInt(selectedManagerId_2)===-2){
                selectedUsers.push( {
                    "management_user_id" : -2,
                    "level" : 2 ,
                    "name" : "Kdokoliv"
                 }  ) 
            }
            else if (parseInt(selectedManagerId_2) === -200){
                selectedUsers.push( {
                    "management_user_id" : -200,
                    "level" : 1 ,
                    "name" : "Přeskočit"
                 }  ) 
            }
            else{
                for (let i = 0; i<management_users_list_2.length; i++){
                    u = management_users_list_2[i]
                    id = u["id"]
                    user_id = u["user_id"]
                    level = u["level"]
                    myname = 'Úroveň '+level +': '+u["name"]
                    if (parseInt(id) === parseInt(selectedManagerId_2) ){
                        console.log("found",u)
                        selectedUsers.push( {"management_user_id" : user_id , "level" : level, 
                        "name" : myname}  ) 
                    }
                }
            }
        }

        if (management_users_list_3.length>0){
            console.log("searching for lvl3 ",selectedManagerId_3)
            if (parseInt(selectedManagerId_3)===-3){
                selectedUsers.push( {
                    "management_user_id" : -3,
                    "level" : 3 ,
                    "name" : "Kdokoliv"
                 }  ) 
            }
            else if (parseInt(selectedManagerId_3) === -300){
                selectedUsers.push( {
                    "management_user_id" : -300,
                    "level" : 1 ,
                    "name" : "Přeskočit"
                 }  ) 
            }
            else{
                for (let i = 0; i<management_users_list_3.length; i++){
                    u = management_users_list_3[i]
                    id = u["id"]
                    user_id = u["user_id"]
                    level = u["level"]
                    myname = 'Úroveň '+level +': '+u["name"]
                    if (parseInt(id) === parseInt(selectedManagerId_3) ){
                        console.log("found",u)
                        selectedUsers.push( {"management_user_id" : user_id , "level" : level, 
                        "name" : myname}  ) 
                    }
                }
            }
        }

        if (management_users_list_4.length>0){
            console.log("searching for lvl4 ",selectedManagerId_4)
            if (parseInt(selectedManagerId_4)===-4){
                selectedUsers.push( {
                    "management_user_id" : -4,
                    "level" : 4 ,
                    "name" : "Kdokoliv"
                 }  ) 
            }
            else if (parseInt(selectedManagerId_4) === -400){
                selectedUsers.push( {
                    "management_user_id" : -400,
                    "level" : 1 ,
                    "name" : "Přeskočit"
                 }  ) 
            }
            else{
                for (let i = 0; i<management_users_list_4.length; i++){
                    u = management_users_list_4[i]
                    id = u["id"]
                    user_id = u["user_id"]
                    level = u["level"]
                    myname = 'Úroveň '+level +': '+u["name"]
                    if (parseInt(id) === parseInt(selectedManagerId_4) ){
                        console.log("found",u)
                        selectedUsers.push( {"management_user_id" : user_id , "level" : level, 
                        "name" : myname}  ) 
                    }
                }
            }
        }

        if (management_users_list_5.length>0){
            console.log("searching for lvl5 ",selectedManagerId_5)
            if (parseInt(selectedManagerId_5)===-5){
                selectedUsers.push( {
                    "management_user_id" : -5,
                    "level" : 5 ,
                    "name" : "Kdokoliv"
                 }  ) 
            }
            else if (parseInt(selectedManagerId_5) === -300){
                selectedUsers.push( {
                    "management_user_id" : -300,
                    "level" : 1 ,
                    "name" : "Přeskočit"
                 }  ) 
            }
            else{
                for (let i = 0; i<management_users_list_5.length; i++){
                    u = management_users_list_5[i]
                    id = u["id"]
                    user_id = u["user_id"]
                    level = u["level"]
                    myname = 'Úroveň '+level +': '+u["name"]
                    if (parseInt(id) === parseInt(selectedManagerId_5) ){
                        console.log(u)
                        selectedUsers.push( {"management_user_id" : user_id , "level" : level, 
                        "name" : myname}  ) 
                    }
                }
            }
        }
        console.log(selectedUsers)
        return selectedUsers
    }
    
    function isNobodySelected (data){
        let selectedUsers = 0
        data.forEach(d => {
            
            if (parseInt(d["management_user_id"]) > -5){
                console.log(d["management_user_id"])
                selectedUsers +=1
            }
        })
        console.log(selectedUsers)
        if (selectedUsers === 0){
            return true
        }
        else{
            return false
        }
      
        
    }

    const selectManagerChange_1 = (e) => {
        setSelectedManagerId_1(e.target.value)
        console.log(e.target.value)
    }

    const selectManagerChange_2 = (e) => {
        setSelectedManagerId_2(e.target.value)
        console.log(e.target.value)
    }
    
    const selectManagerChange_3 = (e) => {
        setSelectedManagerId_3(e.target.value)
        console.log(e.target.value)
    }
    const selectManagerChange_4 = (e) => {
        setSelectedManagerId_4(e.target.value)
        console.log(e.target.value)
    }

    const selectManagerChange_5 = (e) => {
        setSelectedManagerId_5(e.target.value)
        console.log(e.target.value)
    }


    const managerClick = () =>{
        console.log(selectedManagerId_1,selectedManagerId_2,selectedManagerId_3,selectedManagerId_4,selectedManagerId_5)
        const data = getSelectedManagerUsers()
        console.log(data)
       
        if (isNobodySelected(data)===true){
            setBottomMessage("Vyberte schvalovatele alespoň na jedné úrovni.")
        }
        else{
            clicked(data, [docsToApproval_noTree, docsToApproval_withTree], docsWithoutApproval, "management_users")
        }
        
    }

    const directForwardClick = ()=>{
        clickedDirectForward([docsToApproval_noTree, docsToApproval_withTree], docsWithoutApproval, "direct")
    }


    const topManagerClick = () => {
        clicked([{ "name" : ""} ], [docsToApproval_noTree, docsToApproval_withTree], docsWithoutApproval, "top_manager")
    }

    
    let modaltitle = 'Výběr schvalovací cesty'
    
    
    if (assignable_users.data && management_users_list.length===0 && maintenance_users_list.length===0){
        // TOP MANAGER CASE
        topManagerClick()
    }

    //direct force forward if doc type has suddenly become miscellaneous after save
    if (miscellaneousDocType===true){
        directForceForward()
    }

    let directForwardTitle = 'Odeslat přímo k účetní'
    if (docsToApproval_withTree.length > 0){
        directForwardTitle = "Odeslat přímo k účetní \n nebo pokračovat po cestě"
    }

    let buttonStyle1 = location.pathname.includes('my_documents/')? styles.detailButton1 : styles.listButton1
    let buttonStyle2 = location.pathname.includes('my_documents/')? styles.detailButton2 : styles.listButton2

    console.log(maintenance_users_list)
    if (management_users.length===0 && maintenance_users.length===0 && docsToApproval_noTree.length===0 && docsWithoutApproval.length===0) { 
        return (
            <></>//<ProgressSpinner></ProgressSpinner>
        )
    }
    else if (infos_docsToApproval_noTree.length===0 && infos_docsToApproval_withTree===0 && infos_docsWithoutApproval===0){
        return (
            <></>//<ProgressSpinner></ProgressSpinner>
        )
    }
    else{
        
        return (
            <div className={styles.ontop}>
            <Modal name={modaltitle} close={close}>

                <div className={styles.docColumn}>
                { (docsToApproval_noTree.length>0 && user["role"]!=="ClientUser")   && <>
                <h4>Dokumenty ke schválení:</h4>
                <div className={styles.approvalDocuments}>
                    { 
                    infos_docsToApproval_noTree.map((d) => <>  &#8226; {d} <br></br></>
                    )}
                    { 
                    infos_docsToApproval_withTree.map((d) => <>  &#8226; {d} <br></br></>
                    )}
                </div>
                </>
                }
                {(docsWithoutApproval.length>0 && user["role"]!=="ClientUser") && <>
                <h4>Dokumenty, které není třeba schvalovat:</h4>
                <div className={styles.withoutApprovalDocuments}>
                    {
                    infos_docsWithoutApproval.map((d) => <>  &#8226; {d} <br></br></>
                    )}
                </div>
                </>
                }
                 {( user["role"]==="ClientUser") && <>
                <h4>Dokumenty k odeslání:</h4>
                <div className={styles.approvalDocuments}>
                    { 
                    infos_docsToApproval_noTree.map((d) => <>  &#8226; {d} <br></br></>
                    )}
                    { 
                    infos_docsToApproval_withTree.map((d) => <>  &#8226; {d} <br></br></>
                    )}
                    { 
                    infos_docsWithoutApproval.map((d) => <>  &#8226; {d} <br></br></>
                    )}
                </div>
                </>
                }
                </div>
                <div className={styles.body}>
                
                
                <div className={styles.column}>
                <div className={styles.columnManagers}>
                {management_users_list_1.length>0 &&
                <>
                <div className={styles.columnL}>
                <h4> Schvalovatel 1. úrovně: </h4>
                </div>
                            <Select
                                name = {selectedManager}
                                onChange={selectManagerChange_1}
                                data={management_users_list_1}
                                onlyvalid ={true}
                                //noBlankOption={true}
                                >
                                Schvalovatel
                            </Select>
                </>
                }
                {management_users_list_2.length>0 &&
                <>
                <div className={styles.columnL}>
                <h4> Schvalovatel 2. úrovně: </h4>
                </div>
                            <Select
                                name = {selectedManager}
                                onChange={selectManagerChange_2}
                                data={management_users_list_2}
                                onlyvalid ={true}
                                >
                                Schvalovatel
                            </Select>

                </>
                }
                {management_users_list_3.length>0 &&
                <>
                <div className={styles.columnL}>
                <h4> Schvalovatel 3. úrovně: </h4>
                </div>
                            <Select
                                name = {selectedManager}
                                onChange={selectManagerChange_3}
                                data={management_users_list_3}
                                onlyvalid ={true}
                                >
                                Schvalovatel
                            </Select>
                </>
                }
                {management_users_list_4.length>0 &&
                <>
                <div className={styles.columnL}>
                <h4> Schvalovatel 4. úrovně: </h4>
                </div>
                            <Select
                                name = {selectedManager}
                                onChange={selectManagerChange_4}
                                data={management_users_list_4}
                                onlyvalid ={true}
                                >
                                Schvalovatel
                            </Select>
                </>
                }
                {management_users_list_5.length>0 &&
                <>
                <div className={styles.columnL}>
                <h4> Schvalovatel 5. úrovně: </h4>
                </div>
                            <Select
                                name = {selectedManager}
                                onChange={selectManagerChange_5}
                                data={management_users_list_5}
                                onlyvalid ={true}
                                >
                                Schvalovatel
                            </Select>
                </>
                }
                </div>

                <div style={{ padding: '1rem', paddingBottom: '0.5rem', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                 <div className={styles.bottommessage}>    
                 {bottomMessage}
                 </div>
                            <Button  className={buttonStyle1} children={<b>Vybrat schvalovatele</b>} primary onClick={managerClick} />  
                
                {toggle_direct_forward_to_accountant ===true &&
                 <div className={styles.directbutton}>      
                        <Button className={buttonStyle2}  children={directForwardTitle} onClick={directForwardClick} />  
                </div>
                }
                </div>
                {
                    (management_users_list.length===0 && maintenance_users_list.length===0) &&
                    <div className={styles.bottombutton}>      
                    <Button children={'Odeslat'} onClick={topManagerClick} />  
                    </div>
                }
                
                </div>
         
                 
                </div>
               
            </Modal>
            </div>
        )
    }
    
}; 