import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {Form, Field, withFormik} from 'formik';

import {Button} from 'components/Button';
import {DatePicker} from 'components/DatePicker';
import {Select} from 'components/Select';
import styles from 'css_modules/FilterBar.module.css';

const MyForm = (
    {
        handleChange,
        setFilter,
        filter,
        role,
        fields,
        visibleFields,
        accountingYears,
        clientCompanies,
        activities,
        centres,
        contracts,
        docTypes,
        numSeries,
        accountCode,
        vatClassification,
        vatKvClassification,
        accounts,
        countries,
        currencies,
        taxRates,
        paymentTypes,
        checkedDocs,
        setCheckedDocs,
        focusedDocId,
        setFocusedDocId,
        selectedCentre,
        selectedActivity,
        selectedContract,
        selectedNumSeries,
        user,
    }
) => {
    
    let [contractOptions, setContractOptions] = useState("none");
    let [activityOptions, setActivityOptions] = useState("none");
    let [centreOptions, setCentreOptions] = useState("none");

    const contract_number_idx = visibleFields.indexOf("default.document_info.contract_number");
    const contract_title_idx = visibleFields.indexOf("default.document_info.contract_title");
    if (contract_number_idx>=0 && contract_title_idx>=0){
        visibleFields.splice(contract_number_idx, 1);
        setContractOptions("both")
    }
    
    
    const activity_code_idx = visibleFields.indexOf("default.document_info.activity_code");
    const activity_title_idx = visibleFields.indexOf("default.document_info.activity_title");
    if (activity_code_idx>=0 && activity_title_idx>=0){
        visibleFields.splice(activity_code_idx, 1);
        setActivityOptions("both")
    }
    

    
    const centre_code_idx = visibleFields.indexOf("default.document_info.centre_code");
    const centre_title_idx = visibleFields.indexOf("default.document_info.centre_title");
    if (centre_code_idx>=0 && centre_title_idx>=0){
        visibleFields.splice(centre_code_idx, 1);
        setCentreOptions("both")
    }

    
    
   
    
    
    const allCentres = centres
    const allActivities = activities
    const allContracts = contracts
    const allNumSeries = numSeries

    let [workingFilter, setWorkingFilter] = useState({});
    let [formValues,setFormValues] = useState({})
    
    let [selectedYear,setSelectedYear] = useState();
    let [selectedOptions,setSelectedOptions] = useState({})
    
    const blank = '(nevyplněno)';
    const blankValue = 'none';
    const availableYears = [...new Set(accountingYears.map(accountingYear => accountingYear.year))].sort().reverse();
    
    const location = useLocation();
    
    function getAvailableData (selectedYear, data) {
        let availableData = [];
        
        if (selectedYear == null){
            availableData.push( {title: 'Zvolte konkrétní účetní rok', off: 'true'});
        }
        else{
            for (const c of data){
                if ( String(c.year) === String(selectedYear) ){
                    availableData.push(c);
                }
            }
        }
        return availableData;
    }
  
    let savedFilter = ""
    
    if (location.pathname.includes('my_documents')){
        savedFilter = localStorage.getItem("myDocumentsSearchString"+user.pk+user.role)
    }
    else if (location.pathname.includes('documents')){
        savedFilter = localStorage.getItem("documentsSearchString"+user.pk+user.role)
    }
    else if (location.pathname.includes('archive')){
        savedFilter = localStorage.getItem("archiveSearchString"+user.pk+user.role)
    }
    else if (location.pathname.includes('templates')){
        savedFilter = localStorage.getItem("templatesSearchString"+user.pk+user.role)
    }

    if (savedFilter !== undefined && savedFilter!=="" && savedFilter!==null){
        savedFilter = JSON.parse(String(savedFilter))
        if (savedFilter.hasOwnProperty("accounting_year") && selectedYear === undefined){
            setSelectedYear(savedFilter["accounting_year"]        )
        }
        
        if (JSON.stringify(filter) !== JSON.stringify(savedFilter)){
            setFilter(savedFilter)
        }
    }
    else{
            savedFilter = {}
    }

    

    
    if ((workingFilter["location"] !== savedFilter["location"]) || 
        (Object.keys(workingFilter).length===0 && JSON.stringify(savedFilter) !== JSON.stringify(workingFilter))){
        workingFilter = savedFilter
        setWorkingFilter(savedFilter);

        let initformvals = {}
        Object.keys(workingFilter).forEach(key => {
               initformvals[key] = savedFilter[key]  
               //console.log('setting ' + key +" to " + savedFilter[key] )
            }
        )
        
        setFormValues(initformvals)
    }
    
    

    const searchForDataByYear = (title, year, data) => {
        for (let i=0; i<data.length; i++){
            let c = data[i];
            if (String(c.title) === String(title) && String(c.year) === String(year) ){
                return c.id;
            }
        }
    }

    const findDataById=(id, data)=>{
        for (let i=0; i<data.length; i++){
            let c = data[i];
            if (String(c.id) === String(id) ){
                return c;
            }
        }
    }

    const ormFormat = (str) => {
        let ormFormatedStr = str.replace("document_info", 'info').replace('.', '__');
        if(str === 'note'){
            ormFormatedStr = 'info__note';
        }
        if(ormFormatedStr.startsWith('state')){
            ormFormatedStr = 'state';
        }
        
        return ormFormatedStr;
    }

    
    function setFilterOptions  () {
        let id = -1;
        let centreFiltered = null;
        let whereCentre = -1;
        let activityFiltered = null;
        let whereActivity = -1;
        let contractFiltered = null;
        let whereContract = -1;
        let numSeriesFiltered = null;
        let whereNumSeries = -1;

        let seleOptions = {}

        id = -1;
        let filterData = [];
        Object.keys(workingFilter).forEach(key => {
            if ( ormFormat(key) === 'accounting_year' && parseInt(workingFilter[key])===-1 ){
                  
            }
            else{
                filterData.push(ormFormat(key) + '=' + workingFilter[key]);
                //console.log("pushing")
                //console.log(ormFormat(key) + '=' + filter[key]);
                id+=1;
                if (ormFormat(key)==='info__centre'){
                    centreFiltered=workingFilter[key];
                    whereCentre=id;
                }
                else if (ormFormat(key)==='info__activity'){
                    activityFiltered=workingFilter[key];
                    whereActivity=id;
                }
                else if (ormFormat(key)==='info__contract'){
                    contractFiltered=workingFilter[key];
                    whereContract=id;
                }
                else if (ormFormat(key)==='info__num_series'){
                    numSeriesFiltered=workingFilter[key];
                    whereNumSeries=id;
                }
                else{ 
                    seleOptions[key]=workingFilter[key]
                }

            }
        });
        
        if (JSON.stringify(seleOptions)!==JSON.stringify(selectedOptions)){
            setSelectedOptions(seleOptions)
        }
        
        
        function filterAvailableData (selectedData, whereData, dataFiltered, dataName, data) {
            
            /*Available data filtering*/
            if(whereData!==-1){
                if (parseInt(dataFiltered) == null ){
                    filterData.splice(whereData,1);
                }
                else if ( !workingFilter['accounting_year'] ){
                    filterData.splice(whereData,1);
                }
                else if (dataFiltered !== null){
                    let c = findDataById(dataFiltered,data);
                    if (c===undefined ){
                        filterData.splice(whereData,1);
                    }
                    else{
                        if (String(c.year) === String(workingFilter['accounting_year']) ){
                        //console.log("centre ok " + filter['accounting_year']);
                        selectedData = c.id;
                        }
                        else {
                            let correctCentreId = searchForDataByYear( c.title,  workingFilter['accounting_year'], data);
                            let nc = findDataById(correctCentreId, data);
                            
                            if (nc && nc.id>=0){
                                filterData[whereData]=dataName+'='+nc.id;
                                selectedData = nc.id;
                            }
                            else{
                                filterData.splice(whereData,1);
                            }
                        }
                    }
                }
            }
            return selectedData;
        };
        centres = getAvailableData(selectedYear, centres);
        activities = getAvailableData(selectedYear, activities);
        contracts = getAvailableData(selectedYear, contracts);
        numSeries = getAvailableData(selectedYear, numSeries);
        selectedCentre = filterAvailableData(selectedCentre, whereCentre, centreFiltered, 'info__centre', allCentres);
        selectedActivity = filterAvailableData(selectedActivity, whereActivity, activityFiltered, 'info__activity', allActivities);
        selectedContract = filterAvailableData(selectedContract, whereContract, contractFiltered, 'info__contract', allContracts);
        selectedNumSeries = filterAvailableData(selectedNumSeries, whereNumSeries, numSeriesFiltered, 'info__num_series', allNumSeries);
       
    }

    

    function stripObjectName  (key, array) {
        let clonearray = [...array]
        let strippedArray = []
        clonearray.forEach(e => {
            let el = {}
            for (const [key, value] of Object.entries(e)) {
                el[key] = value
            } 
            strippedArray.push(el)
            if (strippedArray[strippedArray.length-1]["title"] !== undefined){
                if (strippedArray[strippedArray.length-1]["title"].split("-").length >1){
                    if (key === "title"){
                        strippedArray[strippedArray.length-1]["title"] = strippedArray[strippedArray.length-1]["title"].split("-")[1]
                    }
                    if (key === "code"){
                        strippedArray[strippedArray.length-1]["title"] = strippedArray[strippedArray.length-1]["title"].split("-")[0]
                    }
                }
                
            }
            
        })
        
        return strippedArray
    }

    
    centres = getAvailableData(selectedYear, centres);
    activities = getAvailableData(selectedYear, activities);
    contracts = getAvailableData(selectedYear, contracts);
    numSeries = getAvailableData(selectedYear, numSeries);

    
    setFilterOptions()


    
    const options = (key) => {
        
        switch(key) {
            case 'document_type':
                return [...docTypes.map(type => ({title: type.name, id: type.slug}))];
            case 'state_':
            case 'state__title':
                return getStateOptions();
            case 'document_info__currency':
                return [...currencies, {title: blank, id: blankValue}];
            case 'client_company':
                return [...clientCompanies, {title: blank, id: blankValue}];
            case 'accounting_year':
                return [...availableYears.map(year => ({title: year, id: year}))];
            case 'document_info__activity':
                return [...activities];
            case 'document_info__activity_code':
                return [...activities];
            case 'document_info__centre':
                return [...centres];
            case 'document_info__contract':
                return [...contracts];
            case 'document_info__contract_number':
                return [...contracts];
            case 'document_info__num_series':
                return [...numSeries];
            case 'document_info__vat_classification':
                return [...vatClassification, {title: blank, id: blankValue}];
            case 'document_info__vat_kv_classification':
                    return [...vatKvClassification, {title: blank, id: blankValue}];
            case 'document_info__supplier_country':
                return [...countries, {title: blank, id: blankValue}];
            case 'document_info__payment_type':
                return [...paymentTypes , {title: blank, id: blankValue}];
            default:
                console.log("Unhandled key", key);
                return [];
        }
    }

    const getStateOptions = () => {
        const options = [
            {id: 'nw', title: 'Nový'},
            {id: 'tf', title: 'K vyplnění'},
            {id: 'ta', title: 'Ke schválení'},
            {id: 'ts', title: 'K zaúčtování'},
            {id: 'sm', title: 'Zpracováno'},
            {id: 'er', title: 'Chyba'},
            {id: 'rj', title: 'Vráceno'},
            //{id: 'arch', title: 'Archivováno'},
            //{id: 'inc', title: 'Konflikt'},
        ];

        switch (location.pathname) {
            case '/documents':
                switch (role) {
                    case 'ClientUser':
                        return options
                    case 'MaintenanceUser':
                        return options
                    case 'OrganizationUnitAdmin':
                        return options
                    case 'ManagementUser':
                        return options//.slice(2, 5)
                    case 'AccountantUser':
                        return options//.slice(3, 5)
                    default:
                        return []
                }

            case '/my_documents':
                switch (role) {
                    case 'ClientUser':
                        return options
                    case 'MaintenanceUser':
                        return options
                    case 'ManagementUser':
                        return options//.slice(2, 5)
                    case 'AccountantUser':
                        return options//.slice(3, 5)
                    default:
                        return []
                }
            case '/archive':
                if (user.role === 'OrganizationUnitAdmin'){
                    return [
                        {id: 'ip', title: 'Zaúčtováno'},
                        {id: 'rm', title: 'Odstraněno'},
                        {id: 'arch', title: 'Archivováno'},
                        {id: 'inc', title: 'Konflikt'},
                    ]
                }
                else{
                    return [
                        {id: 'ip', title: 'Zaúčtováno'},
                        {id: 'rm', title: 'Odstraněno'},
                        {id: 'arch', title: 'Archivováno'}
                    ] 
                }
                
            default:
                return []
        }
    }
    
    
    
    const filterChange = (event, data=null, set = false) => {
        let newFilter = {...workingFilter};
        
        if(data){
            if(data === 'none'){
                delete newFilter[event];
            }else {
                newFilter[event] = data;
            }
        }else {
            handleChange(event);
            if (event.target.name) {
                newFilter[event.target.name] = event.target.value;
                if(event.target.name === 'accounting_year'){
                    setSelectedYear(event.target.value)
                }
            } else {
                delete newFilter[event.target.name];
            }
        }

        let newformValues = formValues
        
        //console.log(event.target)
        
        if (event.target !==undefined){
            newformValues[event.target.name] = event.target.value
        }
        setFormValues(newformValues)

        workingFilter = newFilter

        centres = getAvailableData(selectedYear, centres);
        activities = getAvailableData(selectedYear, activities);
        contracts = getAvailableData(selectedYear, contracts);
        numSeries = getAvailableData(selectedYear, numSeries);

        setFilterOptions()

        setWorkingFilter({...workingFilter});

            
    }
    setFilterOptions()
    const clickonfilter = (e) => {
        
        //console.log('new filter')
        //console.log({...workingFilter})
        setFilter({...workingFilter});
        ///filterChange(e,{...filter},true)
        workingFilter["location"] = location.pathname
        if (location.pathname.includes('my_documents')){
            localStorage.setItem("myDocumentsSearchString"+user.pk+user.role, JSON.stringify(workingFilter))
        }
        else if (location.pathname.includes('documents')){
            localStorage.setItem("documentsSearchString"+user.pk+user.role, JSON.stringify(workingFilter))
        }
        else if (location.pathname.includes('archive')){
            localStorage.setItem("archiveSearchString"+user.pk+user.role, JSON.stringify(workingFilter))
        }
        else if (location.pathname.includes('templates')){
            localStorage.setItem("templatesSearchString"+user.pk+user.role, JSON.stringify(workingFilter))
        }
    }
        
    
    const resetForm = () => {
        let filter = {};
        visibleFields.forEach((item) => {
            let keys = item.split(/\./);
            let fieldData;
            let key;
            if (keys.length === 2) {
                fieldData = fields[keys[0]].items[keys[1]];
                key = keys[1];
            } else {
                fieldData = fields[keys[0]].items[keys[1] + '.' + keys[2]];
                key = keys[1] + '__' + keys[2];
            }
            if (fieldData.type === 'date') {
                filter[key + '_from'] = '';
                filter[key + '_to'] = '';
            } else if (fieldData.type === 'select'){
                filter[key] = '';
                if(key.endsWith('_title')){
                    let ad = key.replace('_title', '');
                    filter[ ad ] = ''
                }
            } else {
                filter[key] = '';
                checkedDocs = [];
                focusedDocId = [];
            }
        });
        filter={}
        setFormValues({})
        selectedYear = undefined
        setSelectedYear(undefined)
        setFilter(filter);
        setWorkingFilter(filter);
        setCheckedDocs(checkedDocs);
        setFocusedDocId(focusedDocId);

       
        setSelectedOptions({})
       

        centres = getAvailableData(selectedYear, centres);
        activities = getAvailableData(selectedYear, activities);
        contracts = getAvailableData(selectedYear, contracts);
        numSeries = getAvailableData(selectedYear, numSeries);

        let emptyFilter = {}
        emptyFilter["location"]=location.pathname
        if (location.pathname.includes('my_documents')){
            localStorage.setItem("myDocumentsSearchString"+user.pk+user.role, JSON.stringify(emptyFilter))
        }
        else if (location.pathname.includes('documents')){
            localStorage.setItem("documentsSearchString"+user.pk+user.role, JSON.stringify(emptyFilter))
        }
        else if (location.pathname.includes('archive')){
            localStorage.setItem("archiveSearchString"+user.pk+user.role, JSON.stringify(emptyFilter))
        }
        else if (location.pathname.includes('templates')){
            localStorage.setItem("templatesSearchString"+user.pk+user.role, JSON.stringify(emptyFilter))
        }
    }
    const getFormClassName = () => {
        return styles.fullForm
    };

    //console.log('filter ')
    //console.log(filter)

    const addInput = () => {
        let inputs = [];

        visibleFields.forEach((item) => {
            let keys = item.split(/\./);
            let fieldData;
            let key;
            if (keys.length === 2) {
                fieldData = fields[keys[0]].items[keys[1]];
                key = keys[1];
            } else {
                fieldData = fields[keys[0]].items[keys[1] + '.' + keys[2]];
                key = keys[1] + '__' + keys[2];
            }
            if(item.endsWith("total")){
                inputs.push(
                    <label key={key + '_from'}>{fieldData.name} od
                        <Field name={key + '_from'} onChange={filterChange}/>
                    </label>
                );
                inputs.push(
                    <label key={key + '_to'}>{fieldData.name} do
                        <Field name={key + '_to'} onChange={filterChange}/>
                    </label>
                );
                return;
            }
            if(item.endsWith("tax_value")){
                inputs.push(
                    <label key={key + '_from'}>{fieldData.name} od
                        <Field name={key + '_from'} onChange={filterChange}/>
                    </label>
                );
                inputs.push(
                    <label key={key + '_to'}>{fieldData.name} do
                        <Field name={key + '_to'} onChange={filterChange}/>
                    </label>
                );
                return;
            }
            if(item.endsWith("value_original")){
                inputs.push(
                    <label key={key + '_from'}>{fieldData.name} od
                        <Field name={key + '_from'} onChange={filterChange}/>
                    </label>
                );
                inputs.push(
                    <label key={key + '_to'}>{fieldData.name} do
                        <Field name={key + '_to'} onChange={filterChange}/>
                    </label>
                );
                return;
            }

            switch (fieldData.type) {
                case 'string':
                    let displayedValue = ""
                    if (formValues.hasOwnProperty(key)){
                        displayedValue = formValues[ key]
                    }
                    
                    inputs.push(
                        <label key={key}>{fieldData.name}
                            <Field
                                type="text"
                                name={key}
                                value   = {displayedValue}
                                onChange={filterChange}
                            />
                        </label>
                    );
                    break;
                case 'select':
                    key = key.replace('_title', '');
                    if (selectedCentre === -1){
                        selectedCentre = undefined;
                    }
                    if (selectedActivity === -1){
                        selectedActivity = undefined;
                    }
                    if (selectedContract === -1){
                        selectedContract = undefined;
                    }
                    if (selectedNumSeries === -1){
                        selectedNumSeries = undefined;
                    }
                    
                    if (key.includes('document_info__centre')){
                        let opt = []
                        if (key.includes('code')){
                            opt = stripObjectName("code", options('document_info__centre'))
                        }
                        else if (centreOptions==="both"  ){
                            opt =  stripObjectName("", options('document_info__centre'))
                        }
                        else{
                            opt =  stripObjectName("title", options('document_info__centre'))
                        }
                        inputs.push(
                            <label key={key}>{fieldData.name}
                                <Field 
                                    name={'document_info__centre'}
                                    component={Select}
                                    data={ opt  }
                                    onChange={filterChange}
                                    value={selectedCentre}
                                />
                            </label>);
                    }
                    else if (key.includes('document_info__activity')){
                            let opt = []
                            if (key.includes('code')){
                                opt = stripObjectName("code", options('document_info__activity'))
                            }
                            else if (activityOptions==="both"  ){
                                opt =  stripObjectName("", options('document_info__activity'))
                            }
                            else{
                                opt =  stripObjectName("title", options('document_info__activity'))
                            }
                        inputs.push(
                            <label key={key}>{fieldData.name}
                                <Field 
                                    name={'document_info__activity'}
                                    component={Select}
                                    data={opt}
                                    onChange={filterChange}
                                    value={selectedActivity}
                                />
                            </label>);
                    }
                    else if (key.includes('document_info__contract')){
                            let opt = []
                            if (key.includes('number')){
                                opt = stripObjectName("code", options('document_info__contract'))
                            }
                            else if (contractOptions==="both"  ){
                                opt =  stripObjectName("", options('document_info__contract'))
                            }
                            else{
                                opt =  stripObjectName("title", options('document_info__contract'))
                            }
                        inputs.push(
                            <label key={key}>{fieldData.name}
                                <Field 
                                    name={'document_info__contract'}
                                    component={Select}
                                    data={opt}
                                    onChange={filterChange}
                                    value={selectedContract}
                                />
                            </label>);
                    }
                    else if (key==='document_info__num_series'){
                        inputs.push(
                            <label key={key}>{fieldData.name}
                                <Field 
                                    name={key}
                                    component={Select}
                                    data={options(key)}
                                    onChange={filterChange}
                                    value={selectedNumSeries}
                                />
                            </label>);
                    }
                    else if(key==='accounting_year')  {
                        let selVal = undefined
                        if (selectedYear===undefined){
                            selVal = options(key)[0]
                        }
                        else{
                            selVal = selectedYear
                        }
                        
                        inputs.push(
                            <label className={styles.highlightedFieldWrap} key={key}>{fieldData.name}
                                <Field className={styles.highlightedField}
                                    name={key}
                                    component={Select}
                                    data={options(key)}
                                    onChange={filterChange}
                                    firstOption={"Všechny"}
                                    value={selVal}
                                    noBlankOption={true}
                            />
                            </label>);
                    }
                    else{
                        let selVal = undefined
                        if (key in selectedOptions){
                            selVal = selectedOptions[key]
                        }
                        else{
                            selVal = options(key)[0]
                        } 
                      
                        inputs.push(
                        <label key={key}>{fieldData.name}
                            <Field 
                                name={key}
                                component={Select}
                                data={options(key)}
                                onChange={filterChange}
                                value={selVal}
                            />
                        </label>);
                    }
                    
                    break;
                case 'bool':
                    inputs.push(
                        <label key={key}>{fieldData.name}
                            <Field
                                name={key}
                                component={Select}
                                data={[{title: 'Ano', id: 1}, {title: 'Ne', id: 0}]}
                                onChange={filterChange}
                            />
                        </label>
                    );
                    break;
                case 'date':
                    inputs.push(
                        <label key={key + '_from'}>{fieldData.name} od
                            <Field name={key + '_from'} component={DatePicker} onDateChange={filterChange}/>
                        </label>
                    );
                    inputs.push(
                        <label key={key + '_to'}>{fieldData.name} do
                            <Field name={key + '_to'} component={DatePicker} onDateChange={filterChange}/>
                        </label>
                    );
                    break;
                default:
            }
        });

        return inputs;
    };

    return (
        <>
        <Form className={getFormClassName()} style={{ 
                position: 'relative', 
                zIndex: 250
            }} >
            {addInput()}
            <Button type="reset" onClick={resetForm} className={styles.button}>Reset</Button>
            <Button type = "filter" primary={true} name="fbttn" onClick={clickonfilter} className={styles.filterbutton}>Filtrovat</Button>
        </Form>
        
        </>
    )
};

export const FilterBar = withFormik({
    mapPropsToValues: ({filter, visibleFields, fields}) => {
        let initialFilter = {};
        visibleFields.forEach((item) => {
            let keys = item.split(/\./);
            let fieldData;
            let key;

            
            if (keys.length === 2) {
                fieldData = fields[keys[0]].items[keys[1]];
                key = keys[1];
            } else {
                fieldData = fields[keys[0]].items[keys[1] + '.' + keys[2]];
                key = keys[1] + '__' + keys[2];
                }
            
            if ( (fieldData.type && fieldData.type === 'date' )|| item.endsWith("total")) {
                initialFilter[key + '_from'] = '';
                initialFilter[key + '_to'] = '';
            } else {
                initialFilter[key] = '';
            }
            
        });

        
        
        return {...initialFilter ,...filter};
    },
    handleSubmit: () => {
        // never submits
    },
    enableReinitialize: true
})(MyForm)