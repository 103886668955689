import React from 'react';

import {ReactComponent as ChevronLeftIcon} from 'assets/svg/chevron_left.svg';
import {ReactComponent as ChevronRightIcon} from 'assets/svg/chevron_right.svg';
import styles from 'css_modules/Pagination.module.css';
import {Select} from "./Select";


export const ItemsPagination = ({user, currentPage,lastPage, setPage, limit, setLimit, items}) => {
    const setTouched =  () =>{localStorage.setItem("formTouched", "false" )}
    const goToFirst = () => { setTouched(); setPage(1); }
    const goToPrevious = () => { setTouched(); setPage(currentPage - 1); }
    const goToNext = () => { setTouched(); setPage(currentPage + 1); }
    const goToLast = () => { setTouched(); setPage(lastPage); }



    const limits = [10, 20, 30];
    let s = limits.map(lim => {
        return {id: lim, title: lim}
    })
    const selectChange = (e) => {
        setTouched()
        let value = e.target.value;
        localStorage.setItem("limitItems"+user.pk, ""+parseInt(value) )
        setLimit(value);
        setPage(1);
    }

    

    let maxItems = (currentPage)*limit 
    if (maxItems>items){maxItems=items}
    return (
        <>
        <div className={styles.wrapper}>
            <div className={styles.paginationDiv}>
                <div className={styles.paginationPager}>
                    <div style={{ marginRight: "-0.5em" }}>Zobrazit</div>
                    <Select
                        name={'limit_select'}
                        data={s}
                        onChange={selectChange}
                        notNull={true}
                        value={limit}
                        className={styles.paginator}
                        style={{ backgroundColor: 'white'}}
                    />
                    
                </div>
            </div>
            <div className={styles.pagination}>
                <button disabled={currentPage === 1} onClick={goToFirst} className={styles.button}>
                    <ChevronLeftIcon className={styles.icon}/>
                    <ChevronLeftIcon className={styles.rightIcon}/>
                </button>
                <button disabled={currentPage === 1} onClick={goToPrevious} className={styles.button}>
                    <ChevronLeftIcon className={styles.icon}/>
                </button>
                {currentPage > 4 && currentPage === lastPage && (
                    <button
                    onClick={() => {
                        setTouched();
                        setPage(currentPage - 4);
                        
                      }}
                        className={styles.button}
                    >
                        {currentPage - 4}
                    </button>
                )}
                {currentPage > 3 && lastPage - currentPage < 2 && (
                    <button
                        onClick={() => {
                            setTouched()
                            setPage(currentPage - 3);
                            }}
                        className={styles.button}
                    >
                        {currentPage - 3}
                    </button>
                )}
                {currentPage > 2 && (
                    <button
                        onClick={() => {setTouched();setPage(currentPage - 2);}}
                        className={styles.button}
                    >
                        {currentPage - 2}
                    </button>
                )}
                {currentPage > 1 && (
                    <button onClick={goToPrevious} className={styles.button}>{currentPage - 1}</button>
                )}
                <button disabled className={styles.current}>{currentPage}</button>
                {currentPage < lastPage && (
                    <button onClick={goToNext} className={styles.button}>{currentPage + 1}</button>
                )}
                {lastPage - currentPage > 2 && (
                    <button
                        onClick={() => {setTouched();setPage(currentPage + 2);}}
                        className={styles.button}
                    >
                        {currentPage + 2}
                    </button>
                )}
                {currentPage < 3 && lastPage - currentPage > 3 && (
                    <button
                        onClick={() => {setTouched();setPage(currentPage + 3);}}
                        className={styles.button}
                    >
                        {currentPage + 3}
                    </button>
                )}
                {currentPage < 2 && lastPage - currentPage > 4 && (
                    <button onClick={() => {setTouched();setPage(5);}} className={styles.button}>5</button>
                )}
                <button disabled={currentPage === lastPage} onClick={goToNext} className={styles.button}>
                    <ChevronRightIcon className={styles.icon}/>
                </button>
                <button disabled={currentPage === lastPage} onClick={goToLast} className={styles.button}>
                    <ChevronRightIcon className={styles.icon}/>
                    <ChevronRightIcon className={styles.rightIcon}/>
                </button>
            </div>

            <div>
            <p className={styles.paginationPar}>položky {(currentPage-1)*limit+1}-{maxItems} z {items} </p>
            </div>
        </div>
    </>
    )
}

/**/