import React, {useState} from 'react';
import {Link, Redirect, useLocation,useHistory} from 'react-router-dom';

import {useToast,useModal} from 'helpers/hooks';
import {ExportModal} from "./ExportModal";

import {ReactComponent as AsTemplateIcon} from 'assets/svg/astemplate.svg';
import {ReactComponent as TemplateIcon} from 'assets/svg/template.svg';
import {ReactComponent as CancelIcon} from 'assets/svg/cancel.svg';
import {ReactComponent as EditIcon} from 'assets/svg/edit.svg';
import {ReactComponent as RejectIcon} from 'assets/svg/arrow_down.svg';
import {ReactComponent as ExportIcon} from 'assets/svg/arrow_down.svg';
import {ReactComponent as ViewIcon} from 'assets/svg/view.svg';
import {ReactComponent as UnsubscriptionIcon} from 'assets/svg/minus.svg'
import {ReactComponent as FileIcon} from 'assets/svg/file.svg';
import {ReactComponent as CaptureIcon} from 'assets/svg/cog.svg';
import {ReactComponent as ArchiveIcon} from 'assets/svg/archive.svg';
import {ReactComponent as MergeIcon} from 'assets/svg/merge.svg';
import {formatDate, formatPrice} from 'helpers/tools';
import styles from 'css_modules/DocumentsTableRow.module.css';

export const DocumentsTableRow = (
    {
        checkedDocs, doc, index,focused, permissions, setCheckedDocs, setFocusedDocId, setMainChecked, toggleReject, 
        toggleRemove, toggleMerge,toggleCapture, toggleArchive, toggleTemplate, toggleView, visibleCheckbox, visibleState, toggleUnassign, user, fields, visibleFields,docsCustomTypes,forceDirection
    }
) => {
    const [edit, setEdit] = useState(null);
    const [options, setOptions] = useState(false);
    
    const [exportModal, toggleExportModal] = useModal();
    const closeExportModal = () => {
        toggleExportModal()
    }
    
    const [optionsStyle, setOptionsStyle] = useState(styles.optionsList);

    const handleMouseMove = (e) => {
        const parentRect = e.currentTarget.parentElement.parentElement.parentElement.parentElement.getBoundingClientRect();
        
        const relativeY = (e.clientY-parentRect.top)/parentRect.height

        
        if (forceDirection &&forceDirection===true){
            setOptionsStyle(index<0.8? styles.optionsList : styles.optionsListReversed)
        }
        else{
            setOptionsStyle(relativeY<0.5? styles.optionsList : styles.optionsListReversed)            
        }
    
        
        /*
        if (relativeY>0.5){
            console.log("UP ",relativeY)
        }
        else{
            console.log("DOWN",relativeY)
        }*/
    };

    const setupOptionsEnter = (e) => {
        handleMouseMove(e)
        setOptions(true)
        
    }



    const location = useLocation();
    const history = useHistory();
    const showToast = useToast();

    const activeAndNotWaiting = doc.is_active && !doc.awaiting_capture;
    let canCheck = location.pathname === '/documents' || activeAndNotWaiting || location.pathname === '/archive' 
    const templates = location.pathname === '/my_templates'
    const checked = checkedDocs.includes(doc.uuid);
    const detail = `${location.pathname}/${doc.uuid}/general`;
    const metadata = `${location.pathname}/${doc.uuid}/metadata`;
    const canCapture = activeAndNotWaiting && user.uses_ocr && !doc.captured;
    const canReject = activeAndNotWaiting && permissions.reject // && doc.added_by_client;
    const canArchive = location.pathname === '/my_documents' && activeAndNotWaiting
    const canTemplate = user.toggle_document_templates
    

    let canRemove = (activeAndNotWaiting && permissions.remove);
    
    if (templates===true){
        //if (user && (String(user.first_name+" "+user.last_name) === doc.added_by)) {
            canRemove=true
            canCheck=true
        //}
    }
    

    const detailText = activeAndNotWaiting && permissions.edit ? 'Editovat dokument' : 'Zobrazit detaily';
    const optionsTriggerClass = options ? styles.optionsTriggerActive : styles.optionsTrigger;
    const rowClass = focused ? (activeAndNotWaiting ? styles.focusedRowActive : styles.focusedRow) : styles.row;


    const handleNewDoc = () => {
        let json = {"templateuuid":doc.uuid, "templatename": doc.document_info.supplier_title? doc.document_info.supplier_title: ""}
        sessionStorage.setItem("template",JSON.stringify(json))
        history.push('/upload');
        //window.location.reload();
    }
    function handleExport () {
        toggleExportModal()
    }

    const handleCheckbox = event => {
        setCheckedDocs(
            event.target.checked ? [...checkedDocs, doc.uuid] : checkedDocs.filter(item => item !== doc.uuid)
        );
        setMainChecked(false);
    };
    const handleFocus = () => {
        localStorage.setItem('storedCheckedDoc'+location.pathname, String(doc.uuid));
        setFocusedDocId(doc.uuid);
    }

    const handleCapture = () => {
        if (!doc.file_url) {
            return showToast('Doklady bez souboru nelze vytěžit')
        }
        toggleCapture(doc);
        setOptions(false)
    }
    const handleView = () => {
        toggleView(doc);
        setOptions(false)
    };
    const handleReject = () => {
        toggleReject(doc);
        setOptions(false)
    };
    const handleArchive = () => {
        toggleArchive(doc)
        setOptions(false)
    }
    const handleTemplate = () => {
        toggleTemplate(doc)
        setOptions(false)
    }
    const handleRemove = () => {
        sessionStorage.setItem("removeDoc", JSON.stringify(doc))
        toggleRemove(doc);
        setOptions(false)
    };
    const handleMerge = () => {
        toggleMerge(doc);
        setOptions(false)
    };
    const handleAuthorRemoval = () => {
        toggleUnassign(doc);
        setOptions(false);
    }
    const getDetail = () => setEdit(doc);
    const getCellClass = activeClassName => ( (canCheck && location.pathname !== '/archive') || location.pathname === '/my_templates') ? activeClassName : styles.inactive;
    
    if (edit) {
        return <Redirect push to={detail}/>
    }

    const tooltip = (event, text) => {
        let target = event.target;
        if (target.offsetWidth < target.scrollWidth) {
            target.setAttribute('title', text);
        } else {
            target.setAttribute('title', '');
        }
    };

    
    

    
    const prepareColumns = () => {
        let columns = [];

        visibleFields.forEach((item) => {
            let keys = item.split(/\./);
            
            let customTypeName = undefined
            if (item === "default.document_type"){
                if (docsCustomTypes.data){
                    docsCustomTypes.data.forEach( e=>{
                        if (doc.uuid === e["document"]){
                            customTypeName = e["user_types"][0]["name"]
                        }
                    })
                }
            }
            
            if (keys.length === 2) {
                if (customTypeName === undefined){
                    columns.push(<td key={'i' + doc.id + keys[1]} onClick={handleFocus} onMouseEnter={(event) => {tooltip(event, format(fields[keys[0]].items[keys[1]].type, doc[keys[1]]));}} 
                                onDoubleClick={getDetail} className={getCellClass(styles[fields[keys[0]].items[keys[1]].class])}>{format(fields[keys[0]].items[keys[1]].type, doc[keys[1]])}</td>);
                }
                else{
                    columns.push(<td key={'i' + doc.id + keys[1]} onClick={handleFocus} onMouseEnter={(event) => {tooltip(event, format(fields[keys[0]].items[keys[1]].type, doc[keys[1]]));}} 
                    onDoubleClick={getDetail} className={getCellClass(styles[fields[keys[0]].items[keys[1]].class])}>{format(fields[keys[0]].items[keys[1]].type, customTypeName)}</td>);
                }
                
            } else {
                columns.push(<td key={'i' + doc.id + keys[1] + '.' + keys[2]} onMouseEnter={(event) => {tooltip(event, format(fields[keys[0]].items[keys[1] + '.' + keys[2]].type, doc[keys[1]][keys[2]]));}} onClick={handleFocus} onDoubleClick={getDetail} className={getCellClass(styles[fields[keys[0]].items[keys[1] + '.' + keys[2]].class])}>{format(fields[keys[0]].items[keys[1] + '.' + keys[2]].type, doc[keys[1]][keys[2]])}</td>);
            }
        });

        return columns;
    }

    const format = (type, text) => {
        if (type === 'date') {
            return formatDate(text);
        } else if (type === 'bool'){
            return text ? 'Ano' : 'Ne';
        } else if(type === 'price'){
            return formatPrice(text);
        } else if(type === 'dph'){
            return formatPrice(text);
        } else {
            return text;
        }
    }

    
  

    sessionStorage.setItem("currentuuid", '');
    return (
        <>
        <tr key={doc.uuid} className={rowClass} >
            {
                visibleCheckbox &&
                <td className={styles.checkboxCell}>
                    <input type="checkbox" checked={checked} disabled={!canCheck} onChange={handleCheckbox}/>
                </td>
            }
            {prepareColumns()}
            <td
                onClick={handleFocus}
                onMouseEnter={setupOptionsEnter}
                ///onMouseMove={handleMouseMove}
                onMouseLeave={() => setOptions(false)}
                className={styles.optionsCell}
            >
                <div className={optionsTriggerClass}>
                    {doc.awaiting_capture ? <CaptureIcon className={styles.captureIcon}/> : <span className={styles.dots}>...</span>}
                </div>
                <ul hidden={!options} className={optionsStyle}>
                    <li className={styles.optionsListItem}>
                        <Link to={detail}>
                            <EditIcon className={styles.optionsListIcon}/>
                            <span className={styles.optionsListText}>{detailText}</span>
                        </Link>
                    </li>
                    {canCapture && (
                        <li className={styles.optionsListItem} onClick={handleCapture}>
                            <CaptureIcon className={styles.optionsListIcon}/>
                            <span className={styles.optionsListText}>Vytěžit</span>
                        </li>
                    )}
                    <li className={styles.optionsListItem}>
                        <Link to={metadata}>
                            <FileIcon className={styles.optionsListIcon}/>
                            <span className={styles.optionsListText}>Metadata</span>
                        </Link>
                    </li>
                    {!templates &&<li onClick={handleView} className={styles.optionsListItem}>
                        <ViewIcon className={styles.optionsListIcon}/>
                        <span className={styles.optionsListText}>Zobrazit náhled</span>
                    </li>}
                    { (location.pathname === '/my_documents' || location.pathname === '/archive')  &&
                    <li onClick={handleMerge} className={styles.optionsListItem}>
                        <MergeIcon className={styles.optionsListIcon}/>
                        <span className={styles.optionsListText}>Sloučit vybrané sem</span>
                    </li>
                    }
                    {
                        permissions.accessDocuments && doc.is_active &&
                        <li onClick={handleAuthorRemoval} className={styles.optionsListItem}>
                            <UnsubscriptionIcon className={styles.optionsListIcon}/>
                            <span className={styles.optionsListText}>Zrušit přiřazení</span>
                        </li>
                    }
                    {canReject && (
                        <li onClick={handleReject} className={styles.optionsListItem}>
                            <RejectIcon className={styles.optionsListIcon}/>
                            <span className={styles.optionsListText}>Vrátit</span>
                        </li>
                    )}
                    {canTemplate &&
                    <li onClick={handleNewDoc} className={styles.optionsListItem}>
                        <AsTemplateIcon className={styles.optionsListIcon}/>
                        <span className={styles.optionsListText}>Použít jako šablonu</span>
                    </li>
                    }
                    {!templates && (
                        <li onClick={handleTemplate} className={styles.optionsListItem}>
                            <TemplateIcon className={styles.archiveIcon}/>
                            <span className={styles.optionsListText}>Přidat mezi šablony</span>
                        </li>
                    )}
                    {canArchive && (
                        <li onClick={handleArchive} className={styles.optionsListItem}>
                            <ArchiveIcon className={styles.archiveIcon}/>
                            <span className={styles.optionsListText}>Archivovat</span>
                        </li>
                    )}
                    {false && (
                        <li onClick={handleExport} className={styles.optionsListItem}>
                            <ExportIcon className={styles.exportIcon}/>
                            <span className={styles.optionsListText}>Exportovat</span>
                        </li>
                    )}
                     {canRemove && (
                        <li onClick={handleRemove} className={styles.optionsListItem}>
                            <CancelIcon className={styles.optionsListCancelIcon}/>
                            <span className={styles.optionsListText}>Smazat</span>
                        </li>
                    )}
                    
                    
                </ul>
            </td>
        </tr>
    {exportModal && <ExportModal close={closeExportModal} uuid={doc.uuid}/>}
    </>
    )
};