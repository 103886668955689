import React from 'react';

import {Button} from 'components/Button';
import {DuplicateTableRow} from 'components/DuplicateTableRow';
import {Modal} from 'components/Modal';
import styles from 'css_modules/DuplicateModal.module.css';

export const DuplicateModal = ({duplicates,clickedOk, clickedClose}) => {
    const message = `Vyplněné údaje jsou shodné s ${duplicates.length > 1 ? 'jinými doklady' : 'jiným dokladem'}:`
    let buttonText = 'Odeslat';
    
    console.log(duplicates)
    
    if (duplicates.length===0){return}
    return (
        <div className={styles.ontop}>
        <Modal name="Duplicitní záznam" close={clickedClose}>
            <main className={styles.body}>
                <p>{message}</p>
                <table className={styles.table}>
                    <thead>
                    <tr>
                        <th>
                            ID
                        </th>
                        <th>
                            Stav
                        </th>
                        <th>Vytvořil</th>
                    </tr>
                    </thead>
                    <tbody>
                    {duplicates.map(duplicate => <DuplicateTableRow key={duplicate.uuid} duplicate={duplicate}/>)}
                    </tbody>
                </table>
                <p>Chcete přesto pokračovat?</p>
            </main>
            <footer className={styles.buttons}>
                <Button onClick={clickedClose}>Zrušit</Button>
                <Button primary onClick={clickedOk}>{buttonText}</Button>
            </footer>
        </Modal>
        </div>
    )
};