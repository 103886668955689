import React from 'react';
import {Button} from 'components/Button';
//import {request} from 'helpers/tools';
import {useToast} from 'helpers/hooks';

export const BulkRemoveButton = ({className, docs, toggleRemove}) => {
    const showToast = useToast();

    const handleClick = async () => {
        if (docs.length===0){
            showToast('Vyberte doklady')
        }
        else{
            toggleRemove(docs)
        }
        
        /*
        
        else { 
            const url = '/remove_many_docs/';
            const data = {uuids: docs.map(doc => doc.uuid)};
            await request(url, 'POST', JSON.stringify(data));
            showToast('Doklady byly smazány');
            await new Promise(resolve => setTimeout(resolve, 1000));
            window.location.reload();s
        }*/
    }

    return (
        <Button className={className} onClick={handleClick} style={{ display: 'inline-block', lineHeight: 'normal' }}>Smazat</Button>
    );
};

